const constants = {
    PRIMARY: "primary",
    OUTLINE_PRIMARY: "outline-primary",
    PRIMARY_LIGHT: "primary-light",
    DANGER: "danger",
    PUBLISH_AND_NOTIFY: "Publish and notify",
    PUBLISH: "Publish",
    CONFIRM_DELETE: "Confirm",
    PROCEED: "Proceed",
    CANCEL: "Cancel",
    YES: "Yes",
    NO: "No",
    OK: "Ok",
    CONFIRM_APPROVE: 'Approve',
    CONFIRM_REJECT: 'Reject',
    CONFIRM_BLOCK: 'Block',
    AVAILABLE_MEETINGS_ONLY: 'Available appointments only',
    ALL_MEETINGS: 'All meetings',
    CANCEL_AVAILABLE_APPOINTMENTS: 'Delete only open slots',
    CANCEL_ALL_MEETINGS_BUTTON: 'Cancel all and delete',
    ALL_REPS_ON_SELECTED_DOMAINS: 'All reps in selected domains',
    ONLY_SELECTED_REPS: 'Only selected reps',
    EDIT_MEETING_MSG: `Would you like to notify the attendants with an updated invite? `,
    DELETE_MEETING_MSG: `Are you sure you want to delete this meeting? `,
    RESET_PHYSICIAN_MSG: `Changing the topic, brand or category would reset the selected physicians `,
    CANCEL_MEETING: `Are you sure want to cancel? `,
    PUBLISH_MEETING_MSG: `Are you sure to publish this meeting? `,
    BOOK_MEETING_MSG: `Are you sure to book this meeting? `,
    CONFIRM_MEETING_MSG: `Are you sure to confirm this meeting? `,
    RESET_PASSWORD_MSG: `Your password has been changed`,
    RESET_SUPPORT_MATERIAL_MSG: `Changing material type would reset respective content `,
    APPROVE_EMAIL: `Are you sure you want to approve of these emails? `,
    REJECT_EMAIL: `Are you sure you want to reject of these emails?`,
    DELETE_USER_MSG: `Are you sure you want to delete this user?`,
    BLOCK_USER_MSG: `Are you sure you want to block this user?`,
    APPROVE_USER_MSG: `Are you sure you want to unblock this user?`,
    FAVORITE_USER_MSG: `Are you sure you want this user as favorite?`,
    UNFAVORITE_USER_MSG: `Are you sure to make this user a general representative?`,
    DELETE_LOCATION_MSG: `Are you sure you want to delete this location?`,
    DELETE_POLICY_MSG: `Are you sure you want to delete this policy?`,
    DELETE_PREFERENCE_MSG: `Are you sure you want to delete this preference?`,
    DELETE_APPOINTMENT_TYPE_MSG: `Are you sure you want to delete this appointment type?`,
    DELETE_RESTAURANT_MSG: `Are you sure you want to delete this restaurant?`,
    DELETE_MEETING_RULE_MSG: `Are you sure you want to delete this meeting rule?`,
    DELETE_TEAM_MSG: `Are you sure you want to delete this team?`,
    BLOCK_CANCEL_USER_MSG: `Are you sure you want to block and cancel all the appointments for this user?`,
    BLOCK_TRANSFER_USER_MSG: `Are you sure you want to block and transfer all the appointments for this user?`,
    CANCEL_ALL_MEETINGS: 'Are you sure you want to cancel all the appointments for this user?',
    BLOCK_CANCEL_DOMAIN_USER_MSG: `Are you sure you want to block and cancel all the appointments for this domain users?`,
    UNBLOCK_CANCEL_DOMAIN_USER_MSG: `Are you sure you want to unblock all users from this domain?`,
    UPDATE_AND_NOTIFY: 'Update and notify',
    UPDATE: 'Update',
    TENANT_CHANGE_CONFIRMATION_MSG: 'Are you sure you want to change the practice?',
    REMOVE_PRACTICE_CONFIRMATION_MSG: 'Are you sure you want to remove the practice?',
    PRACTICE_NOT_ALLOWED_MSG: 'You are not allowed to access practiceName practice. Please contact administrator.',
    CONFIRM_GENERATE_MEETING_CARD: ' Do you want to update appointment time for all meetings or only available appointments?',
    DELETE_LOCATION_TIME_SLOT_MSG: 'Are you sure you want to delete this appointment type?',
    CONFIRM_ALL_MEETINGS_OR_AVAILABLE_MEETINGS_MSG: ' Do you want to cancel all meetings or only available appointments?',
    MAKE_AS_DEFAULT_MSG: 'Are you sure you want to make this practice as default practice?',
    APPROVE_ALL_DOMAIN_EMAIL: 'Do you want to approve only selected reps or all reps with the domain?',
    DELETE_PHYSICIAN_MSG: 'Are you sure you want to delete this physician?',
    DELETE_SUBSCRIBER_MSG: 'Are you sure you want to delete this subscriber?',
    CHANGE_MSG: 'Are you sure you want to switch this user?',
    RESET_PASSWORD_ALERT_MSG: 'Are you sure you want to reset password for this user?',
    DELETE_HOLIDAY_MSG: 'Are you sure you want to delete this holiday?',
    SUSPEND_LOCATION_MSG: 'Are you sure you want to suspend appointments for this location?',
    REVOKE_LOCATION_MSG: 'Are you sure you want to unsuspend appointments for this location?',
    CREATE_TRANSFER_MEETING_PENDING_REQUEST: 'You are requesting to transfer to someone outside of your organization, this would require approval by tenantName, please click "Yes" to proceed?',
    FAVORITE_RESTAURANT_MSG: `Are you sure you want this restaurant as favorite?`,
    UNFAVORITE_RESTAURANT_MSG: `Are you sure to make this restaurant a general restaurant?`,
    DELETE_NEWS_ANNOUNCEMENT_MSG: `Are you sure you want to delete this news & announcement?`,
    ADD_POLICY_ALL_LOCATIONS: `Are you sure you want to add this policy for all locations?`,
}

const deleteMeetingAlert = {
    title: "",
    message: constants.CANCEL_MEETING,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const resetPhysicianAlert = {
    title: "",
    message: constants.RESET_PHYSICIAN_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.PROCEED },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL },
    isDontShowCheck: true
}

const cancelMeetingAlert = {
    title: "",
    message: constants.CANCEL_MEETING,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const confirmGenerateMeetingCardAlert = {
    title: "",
    message: constants.CONFIRM_GENERATE_MEETING_CARD,
    primaryBtn: { variant: constants.PRIMARY, text: constants.AVAILABLE_MEETINGS_ONLY },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.ALL_MEETINGS },
    isDontShowCheck: false
}

const publishMeetingAlertContent = {
    title: "",
    message: constants.BOOK_MEETING_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
};
const confirmMeetingAlertContent = {
    title: "",
    message: constants.CONFIRM_MEETING_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
};
const publishAndNotifylert = {
    title: "",
    message: constants.EDIT_MEETING_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.UPDATE_AND_NOTIFY },
    secondaryBtn: { variant: constants.PRIMARY_LIGHT, text: constants.UPDATE },
    isDontShowCheck: false
};
const resetPasswordAlertContent = {
    message: constants.RESET_PASSWORD_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.OK },
    secondaryBtn: null,
    isDontShowCheck: false
};
const resetSupportMaterialAlert = {
    title: "",
    message: constants.RESET_SUPPORT_MATERIAL_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.PROCEED },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL },
    isDontShowCheck: true
}

const approveEmailAlert = {
    title: "",
    message: constants.APPROVE_EMAIL,
    primaryBtn: { variant: constants.PRIMARY, text: constants.CONFIRM_APPROVE },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL },
    isDontShowCheck: false
}

const approveAllDomainEmailAlert = {
    title: "",
    message: constants.APPROVE_ALL_DOMAIN_EMAIL,
    primaryBtn: { variant: constants.PRIMARY, text: constants.ONLY_SELECTED_REPS },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.ALL_REPS_ON_SELECTED_DOMAINS },
    isDontShowCheck: false
}

const rejectEmaillAlert = {
    title: "",
    message: constants.REJECT_EMAIL,
    primaryBtn: { variant: constants.DANGER, text: constants.CONFIRM_REJECT },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL },
    isDontShowCheck: false
}

const deleteUserAlert = {
    title: "",
    message: constants.DELETE_USER_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const blockUserAlert = {
    title: "",
    message: constants.BLOCK_USER_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const approveUserAlert = {
    title: "",
    message: constants.APPROVE_USER_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const favoriteUserAlert = {
    title: "",
    message: constants.FAVORITE_USER_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.OK },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL },
    isDontShowCheck: false
}

const unFavoriteUserAlert = {
    title: "",
    message: constants.UNFAVORITE_USER_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.OK },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL },
    isDontShowCheck: false
}

const deleteLocationAlert = {
    title: "",
    message: constants.DELETE_LOCATION_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deleteRestaurantAlert = {
    title: "",
    message: constants.DELETE_RESTAURANT_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deleteMeetingRuleAlert = {
    title: "",
    message: constants.DELETE_MEETING_RULE_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deleteTeamAlert = {
    title: "",
    message: constants.DELETE_TEAM_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deletePolicyAlert = {
    title: "",
    message: constants.DELETE_POLICY_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deletePreferenceAlert = {
    title: "",
    message: constants.DELETE_PREFERENCE_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deleteAppointmentTypeAlert = {
    title: "",
    message: constants.DELETE_APPOINTMENT_TYPE_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const blockCancelUserAlert = {
    title: "",
    message: constants.BLOCK_CANCEL_USER_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const blockTransferAlert = {
    title: "",
    message: constants.BLOCK_TRANSFER_USER_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const cancelAllMeetingAlert = {
    title: "",
    message: constants.CANCEL_ALL_MEETINGS,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const blockCancelDomainUsersAlert = {
    title: "",
    message: constants.BLOCK_CANCEL_DOMAIN_USER_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const unblockCancelDomainUsersAlert = {
    title: "",
    message: constants.UNBLOCK_CANCEL_DOMAIN_USER_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const changeTenantAlert = {
    title: "",
    message: constants.TENANT_CHANGE_CONFIRMATION_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deletePracticeAlert = {
    title: "",
    message: constants.REMOVE_PRACTICE_CONFIRMATION_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const blockedPracticeAlert = {
    title: "",
    message: constants.PRACTICE_NOT_ALLOWED_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.OK },
    isDontShowCheck: false
}

const deleteLocationTimeSlotAlert = {
    title: "",
    message: constants.DELETE_LOCATION_TIME_SLOT_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const confirmLocationTimeSlotMeetingCancelAlert = {
    title: "",
    message: constants.CONFIRM_ALL_MEETINGS_OR_AVAILABLE_MEETINGS_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.CANCEL_AVAILABLE_APPOINTMENTS },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL_ALL_MEETINGS_BUTTON },
    isDontShowCheck: false
}

const makeAsDefaultAlert = {
    title: "",
    message: constants.MAKE_AS_DEFAULT_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
};

const deletePhysicianAlert = {
    title: "",
    message: constants.DELETE_PHYSICIAN_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deleteSubscriberAlert = {
    title: "",
    message: constants.DELETE_SUBSCRIBER_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const changeUserAlert = {
    title: "",
    message: constants.CHANGE_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}


const resetPasswordAlert = {
    title: "",
    message: constants.RESET_PASSWORD_ALERT_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const deleteHolidayAlert = {
    title: "",
    message: constants.DELETE_HOLIDAY_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const suspendLocationAlert = {
    title: "",
    message: constants.SUSPEND_LOCATION_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

const revokeLocationAlert = {
    title: "",
    message: constants.REVOKE_LOCATION_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}


const createTransferMeetingPendingRequestAlert = {
    title: "",
    message: constants.CREATE_TRANSFER_MEETING_PENDING_REQUEST,
    primaryBtn: { variant: constants.PRIMARY, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
};

const favoriteRestaurantAlert = {
    title: "",
    message: constants.FAVORITE_RESTAURANT_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.OK },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL },
    isDontShowCheck: false
}

const unFavoriteRestaurantAlert = {
    title: "",
    message: constants.UNFAVORITE_RESTAURANT_MSG,
    primaryBtn: { variant: constants.PRIMARY, text: constants.OK },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.CANCEL },
    isDontShowCheck: false
}

const deleteNewsAnnouncementAlert = {
    title: "",
    message: constants.DELETE_NEWS_ANNOUNCEMENT_MSG,
    primaryBtn: { variant: constants.DANGER, text: constants.YES },
    secondaryBtn: { variant: constants.OUTLINE_PRIMARY, text: constants.NO },
    isDontShowCheck: false
}

export {
    deleteMeetingAlert,
    resetPhysicianAlert,
    cancelMeetingAlert,
    publishMeetingAlertContent,
    confirmMeetingAlertContent,
    publishAndNotifylert,
    resetPasswordAlertContent,
    resetSupportMaterialAlert,
    approveEmailAlert,
    rejectEmaillAlert,
    deleteUserAlert,
    blockUserAlert,
    approveUserAlert,
    favoriteUserAlert,
    unFavoriteUserAlert,
    deleteLocationAlert,
    deletePolicyAlert,
    deletePreferenceAlert,
    deleteAppointmentTypeAlert,
    deleteRestaurantAlert,
    deleteMeetingRuleAlert,
    deleteTeamAlert,
    blockCancelUserAlert,
    blockTransferAlert,
    cancelAllMeetingAlert,
    blockCancelDomainUsersAlert,
    unblockCancelDomainUsersAlert,
    changeTenantAlert,
    deletePracticeAlert,
    blockedPracticeAlert,
    confirmGenerateMeetingCardAlert,
    deleteLocationTimeSlotAlert,
    confirmLocationTimeSlotMeetingCancelAlert,
    makeAsDefaultAlert,
    approveAllDomainEmailAlert,
    deletePhysicianAlert,
    deleteSubscriberAlert,
    changeUserAlert,
    resetPasswordAlert,
    deleteHolidayAlert,
    suspendLocationAlert,
    revokeLocationAlert,
    unFavoriteRestaurantAlert,
    favoriteRestaurantAlert,
    createTransferMeetingPendingRequestAlert,
    deleteNewsAnnouncementAlert
};