import Proxy from "./Proxy";

class LocationProxy extends Proxy {
  /**
   * Constructor for Auth proxy
   */
  constructor() {
    super(`/tenantlocations`);
  }

  public updateLocation(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined,body);
  }

  public getLocations(data: any) {
    return this.getList(data);
  }

  public createLocation(data: any) {
    return this.submit("post", `${this.endpoint}`, undefined, data);
  }

  public getListCount(data: any) {
    return this.submit("get", `tenantlocations/count`, {where: JSON.stringify(data)});
  }

  public getPoliciesAndPreferencesByLocationName(locationName: string) {
    return this.submit("get", `tenantlocations/${locationName}/details`);
  }

  public cancelAndDeleteAppointments(data: any) {
    const locationId = data.locationId;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.locationId;
    return this.submit("patch", `tenantlocations/${locationId}/appointments`, undefined, body);
  }

}

export default LocationProxy;
