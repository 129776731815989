const module = {
  USER: 'user',
  DASHBOARD: 'dashboard',
  MEETINGS: 'meetings',
  SUBSCRIBERS: 'subscribers',
  PHYSICIANS: 'physicians',
  RESTAURANTS: 'restaurants',
  PRACTICE: 'practice',
  SUBSCRIPTION: 'subscription',
  TEAMS: 'teams',
  SETTINGS: 'settings',
  PREFERENCES: 'preferences',
  LOCATION_MANAGEMENT: 'location_management',
  APPOINTMENT_TYPES: 'appointment_types',
  MESSAGES: 'messages',
  PHYSICIAN_LOG: 'physicianlog',
  PENDING_APPROVALS: 'pending_approvals',
  POLICIES: 'policies',
  PHARMA_REPS: 'pharma_reps',
  HOLIDAYS: 'holidays',
  MEETING_RULES: 'meeting_rules',
  MEETING_APPROVALS: 'meeting_approvals',
  USER_MANAGEMENT: 'user_management',
  NEWS_ANNOUNCEMENT: 'news_announcements'
}

export default {
  module,
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
  READ: 'read',
  BLOCK_AND_CANCEL_MEETINGS: 'block_and_cancel_meetings',
  BLOCK_AND_TRANSFER_MEETINGS: 'block_and_transfer_meetings',
  BLOCK_LOCATIONS: 'block_locations',
  BLUK_CANCEL_MEETINGS: 'bulk_cancel_meetings',
  BLUK_TRANSFER_MEETING: 'bulk_transfer_meetings',
  CONVERT_TO_USER: 'convert_to_user',
  MESSAGE_TO_ORGANIZER: 'message_to_organizer',
  CANCEL_APPOINTMENTS: 'cancel_appointments',
  CANCEL_AND_DELETE_APPOINTMENTS: 'cancel_and_delete_appointments',
  RESET_PASSWORD: 'reset_password',
  CONVERT_TO_SUBSCRIBER: 'convert_to_subscriber',
  SUSPEND_APPOINTMENTS: 'suspend_appointments',

};
