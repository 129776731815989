





























































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import moment from "moment";
import { copyToClipboard } from "@/utils/copy";
import {
  publishMeetingAlertContent,
  confirmMeetingAlertContent,
} from "@/utils/notificationContent";
import { formatDate } from "@/utils/dateUtils";
import constants from "@/constants/_message";
import arrayMethods from "@/utils/array";
import _permissions from "@/constants/_permissions";

@Component({})
export default class MeetingCard extends Vue {
  @Prop() public index: any;
  @Prop() public tabName: any;
  @Prop() public meeting: any;
  @Prop({ default: true }) public isCollapsible!: boolean;
  @Prop({ default: false }) public isMeetingCompleted!: boolean;
  @Prop({ default: false }) public isTransferMeeting!: boolean;
  @Prop({ default: false }) public isPastMeeting!: boolean;
  @Action public publishMeetingById: any;
  @Action public confirmMeetingById: any;
  @Getter public permissions!: any;
  @Getter public currentUser!: any;
  @Getter public role!: any;
  @Getter public user: any;
  @Action public downloadVaccinationCertification: any;
  @Getter public rolePerm: any;
  @Action public downloadSignedHipaaForm: any;
  @Action public updatePastMeetingById: any;
  perm = _permissions;

  showTooltip = false;
  showMeetingBtnTooltip = false;
  currentDate = `${moment().startOf("day").format("YYYY-MM-DD")}T00:00:00.000Z`;
  isDelivered: any = false;
  isNotDelivered: any = false;

  beforeMount() {
    if (this.isPastMeeting) {
      this.isDelivered = this.meeting.is_delivered;
      this.isNotDelivered = this.meeting.is_delivered;
    }
  }

  hideTooltipLater() {
    setTimeout(() => {
      this.showTooltip = false;
      this.showMeetingBtnTooltip = false;
    }, 300);
  }

  showAttendeeList(): void {
    this.$emit("openAttendeeListModal", this.meeting.participants);
  }

  showPolicyAndPreference(): void {
    this.$emit("showPolicyAndPreference", this.meeting.locationId);
  }

  showRestaurant(): void {
    this.$emit("showRestaurant", this.meeting);
  }

  conformButtonPreferences(meeting: any): boolean {
    if (meeting.status === 2) {
      return false;
    } else if (this.permissions.update && meeting.status === 1 && this.role !== 'ADMIN' && this.role !== 'PHYSICIAN') {
      return true;
    } else if (this.role === 'ADMIN' && this.role !== 'PHYSICIAN' && this.currentUser.id === meeting.organizerId) {
      return true;
    }
    return false;
  }

  showMeetingHistory(): void {
    let arr: any = [];
    if (this.meeting.zoom_history && this.meeting.zoom_history.participants) {
      const val = JSON.parse(
        JSON.stringify(this.meeting.zoom_history.participants)
      );
      val.map((item: any) => {
        const indexVal = arrayMethods.findIndexOfObject(
          arr,
          item.user_email ? "user_email" : "id",
          item.user_email ? item.user_email : item.id
        );
        if (indexVal > -1) {
          arr[indexVal].duration = arr[indexVal].duration + item.duration;
        } else {
          arr.push(item);
        }
      });
    }
    this.$emit("openMeetingHistoryModal", arr);
  }

  setActiveTab(): void {
    this.$emit("setActiveTab", "UPCOMING");
  }

  get meetingJoinUrl() {
    if (this.meeting.zoom_nonzoom_meeting === "zoom") {
      if (this.isCreatedByCurrentUser) {
        return this.meeting.zoom_details.start_url;
      }
      return this.meeting.zoom_details.join_url;
    }
    return this.meeting.nonzoom_meeting_link;
  }

  get isCreatedByCurrentUser() {
    return this.currentUser.id === this.meeting.created_by;
  }

  onJoinMeeting() {
    if (
      (moment(this.meeting.start_time)
        .subtract(5, "minutes")
        .isBefore(moment()) &&
        !this.isCreatedByCurrentUser) ||
      this.isCreatedByCurrentUser
    ) {
      window.open(this.meetingJoinUrl, "_blank");
    }
  }

  onCancelMeeting() {
    this.$emit("cancelMeeting", this.meeting);
  }

  openMessageOrganizerModal() {
    this.$emit("openMessageOrganizerModal", this.meeting);
  }

  onEditMeeting(id: any) {
    this.validateSubscription(() => {
      this.$router.push(
        this.$route.name !== "book-meeting.index"
          ? { path: `/meeting/update/${id}` }
          : { path: `/book-meeting/update/${id}` }
      );
    });
  }

  onViewMeeting(id: any) {
    this.$router.push({ path: `/meeting/view/${id}` });
  }

  onPublishMeeting(id: any) {
    if (!this.meeting.participants || this.meeting.participants.length === 0) {
      alert.methods.appToaster({
        message: constants.ATLEAST_ONE_PARTICIPANT_SELECTED,
        type: "ERROR",
      });
      return;
    }
    if (moment(this.meeting.start_time).isBefore(moment())) {
      return;
    } else {
      alert.methods.conFirmAlert(
        publishMeetingAlertContent,
        () => {
          this.publishMeetingById({
            id,
            callback: () => {
              this.$emit("setActiveTab", "UPCOMING");
            },
          });
        },
        () => { }
      );
    }
  }

  validateSubscription(callback: () => void) {
    let currentTime = moment.utc(
      moment().format("YYYY-MM-DD[T]HH:mm[Z]"),
      "YYYY-MM-DD[T]HH:mm[Z]"
    );
    let subscribtionStartDate = moment.utc(
      this.currentUser.tenant.subscription_start_date,
      "YYYY-MM-DD[T]HH:mm[Z]"
    );
    if (this.currentUser.tenant.subscription_grace_days) {
      subscribtionStartDate = subscribtionStartDate.add(this.currentUser.tenant.subscription_grace_days, 'days')
    }
    if (this.currentUser.userTypesId === 3 && this.currentUser.tenant.enable_subscription && currentTime.isSameOrAfter(subscribtionStartDate)) {
      if (
        this.currentUser.subscription_end_date &&
        this.currentUser.subscription_end_date !== ""
      ) {
        let subscriptionTime = moment.utc(
          this.currentUser.subscription_end_date,
          "YYYY-MM-DD[T]HH:mm[Z]"
        );
        if (this.currentUser.tenant.subscription_grace_days) {
          subscriptionTime = subscriptionTime.add(this.currentUser.tenant.subscription_grace_days, 'days')
        }
        if (subscriptionTime.isSameOrAfter(currentTime)) {
          callback();
        } else {
          alert.methods.appToaster({
            message:
              "Your subscription is expired, Renew subscription to continue our services",
            type: "ERROR",
          });
        }
      } else {
        alert.methods.appToaster({
          message:
            "You don't have a subscribed plan. Kindly subscribe.",
          type: "WARNING",
          noAutoClose: true
        });
      }
    } else {
      callback();
    }
  }

  onConfirmMeeting(id: any) {
    if (moment(this.meeting.start_time).isBefore(moment())) {
      return;
    } else {
      this.validateSubscription(() => {
        alert.methods.conFirmAlert(
          confirmMeetingAlertContent,
          () => {
            this.confirmMeetingById({
              id,
              callback: () => {
                this.$emit("setActiveTab", "UPCOMING");
              },
            });
          },
          () => { }
        );
      });
    }
  }

  copyText(id: any, value?: any) {
    if (value) {
      this.showMeetingBtnTooltip = true;
    } else {
      this.showTooltip = true;
    }
    copyToClipboard(id, value);
  }

  getFormattedDate() {
    const start = formatDate(this.meeting.start_time, "DD MMM YYYY");
    return `${start}`;
  }

  getFormattedDay() {
    return formatDate(this.meeting.start_time, "ddd");
  }

  getFormattedTime() {
    let startTime = '';
    let endTime = '';
    if (!moment(this.meeting.start_time).isDST()) {
      startTime = moment(this.meeting.start_time).add(1, 'hour').format("hh:mm A");
      endTime = moment(this.meeting.end_time).add(1, 'hour').format("hh:mm A");
    } else {
      startTime = formatDate(this.meeting.start_time, "hh:mm A");
      endTime = moment(this.meeting.end_time).format("hh:mm A");
    }
    return `${startTime} - ${endTime}`;
  }

  getImgUrl() {
    var images = require.context("@/assets/icons/", false, /\.png$/);
    return images(
      "./" + (this.meeting.status === 2 ? "Confirmed" : "Pending") + ".png"
    );
  }

  getFormattedAttendeeList(): string {
    if (this.meeting.participants.length === 0) {
      return "";
    }
    const physicianFullNames = this.meeting.participants.map(p => `${p.prefix} ${p.first_name} ${p.last_name} (${p.department_name})`)
    if (physicianFullNames.length === 1) {
      return `${physicianFullNames[0]}`;
    } else if (physicianFullNames.length === 2) {
      return `${physicianFullNames[0]} and ${physicianFullNames[1]}`;
    } else {
      return `${physicianFullNames[0]} and ${physicianFullNames.length - 1
        } others`;
    }
  }

  onTransferMeetingClick(meetingId: any) {
    this.validateSubscription(() => {
      return this.$emit("openTransferModal", meetingId);
    })
  }

  downloadVaccinationCertificate(certificateUrl: any) {
    this.downloadVaccinationCertification(certificateUrl);
  }

  downloadHipaaForm(hipaaFilrUrl: any) {
    this.downloadSignedHipaaForm(hipaaFilrUrl);
  }

  get isEnabledHipaa() {
    return this.currentUser.tenant && this.currentUser.tenant.tenantpreferences &&
      this.currentUser.tenant.tenantpreferences.required_fields &&
      this.currentUser.tenant.tenantpreferences.required_fields.enable_hipaa_form;
  }

  updateDelivered(id: number) {
    let is_delivered = '';
    if (this.isDelivered === 'Y' || this.isDelivered === '') {
      is_delivered = this.isDelivered;
    }
    if (this.isNotDelivered === 'N' || this.isNotDelivered === '') {
      is_delivered = this.isNotDelivered;
    }
    this.updatePastMeetingById({ data: { id, is_delivered }, callback: () => { } });
  }

  showRestaurantModal(): void {
    this.$emit("updateRestaurant", {
      id: this.meeting.id,
      restaurantsId: this.meeting.restaurantsId
    });
  }

  blockCancelAppointment(meeting:any){
    const blockCancelAppt = this.currentUser.tenant?.tenantpreferences?.required_fields?.block_cancel_appointment ?? 0;
    const sDate = moment(meeting.start_time).subtract(blockCancelAppt,'hours');
     return this.currentUser.userTypesId === 3 && moment().isSameOrAfter(sDate)    
  }
}
